.cardStyle {
    width: 25em;
}

.fullSize {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
}

.loginButton {
    width: 100%;
}

.p-card-title {
    text-align: center;
}

.spinner {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
}
